<template>
  <div>
    <div
      v-if="dataLoad"
      class=" text-center my-2"
    >
      <b-spinner
        type="grow"
        label="Loading..."
      />
    </div>
    <b-row v-else>
      <b-col
        cols="12"
        md="12"
      >
        <div
          v-if="viewData.national_id"
          class="contact-box center-version"
        >
          <div class="top text-center">
            <img
              v-if="viewData.logo"
              alt="image"
              class="img-circle"
              :src="viewData.logo"
              width="150"
            >
            <h3 class="m-b-xs pt-1">
              <strong> {{ viewData.name }} </strong>
            </h3>
            <div class="font-bold">
              @{{ viewData.business_name }}
            </div>
            <small>
              NID: {{ viewData.national_id }}
            </small>
          </div>
          <div class="user-info mt-2">
            <app-collapse
              accordion
              type="margin"
            >
              <app-collapse-item title="Merchant Details">
                <div class="details">
                  <ul>
                    <li>Merchant name: {{ viewData.business_name }} </li>
                    <li>Commercial Registry NO: {{ viewData.crn }} </li>
                    <li>Main Wallet NO: {{ viewData.phone }} </li>
                    <li>Comapny land line: {{ viewData.tel }} </li>
                    <li v-if="viewData.phone2">
                      Other mobile number: {{ viewData.phone2 }}
                    </li>
                    <li>Number of branches: {{ viewData.branch_no }}
                      <ul v-if="viewData.branch_address_1">
                        <li v-if="viewData.branch_address_1">
                          Branch 1 address:  {{ viewData.branch_address_1 }}
                        </li>
                        <li v-if="viewData.branch_address_2">
                          Branch 2 address:  {{ viewData.branch_address_2 }}
                        </li>
                        <li v-if="viewData.branch_address_3">
                          Branch 3 address:  {{ viewData.branch_address_3 }}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </app-collapse-item>
              <app-collapse-item title="National Id front">
                <div class="ids text-center d-flex justify-content-between">
                  <div class="ids-img">
                    <img
                      v-if="viewData.national_id_front"
                      alt="image"
                      class="img-circle"
                      :src="viewData.national_id_front"
                      width="200"
                    >
                  </div>
                  <div class="download mt-1">
                    <a
                      :href="viewData.national_id_front"
                      title="Download"
                      class="download-btn"
                      :download="viewData.national_id_front"
                      target="_blank"
                    >
                      <feather-icon
                        size="18"
                        icon="DownloadIcon"
                      />
                    </a>
                  </div>
                </div>
              </app-collapse-item>
              <app-collapse-item title="National Id back">
                <div class="ids text-center d-flex justify-content-between">
                  <div class="ids-img">
                    <img
                      v-if="viewData.national_id_back"
                      alt="image"
                      class="img-circle"
                      :src="viewData.national_id_back"
                      width="200"
                    >
                  </div>
                  <div class="download mt-1">
                    <a
                      :href="viewData.national_id_back"
                      title="Download"
                      class="download-btn"
                      :download="viewData.national_id_back"
                      target="_blank"
                    >
                      <feather-icon
                        size="18"
                        icon="DownloadIcon"
                      />
                    </a>
                  </div>
                </div>
              </app-collapse-item>
              <app-collapse-item title="Commercial Registry file">
                <div class="ids text-center d-flex justify-content-between">
                  <div class="ids-img">
                    <img
                      v-if="viewData.crn_file"
                      alt="image"
                      class="img-circle"
                      :src="viewData.crn_file"
                      width="200"
                    >
                  </div>
                  <div class="download mt-1">
                    <a
                      :href="viewData.crn_file"
                      title="Download"
                      class="download-btn"
                      :download="viewData.crn_file"
                      target="_blank"
                    >
                      <feather-icon
                        size="18"
                        icon="DownloadIcon"
                      />
                    </a>
                  </div>
                </div>
              </app-collapse-item>
            </app-collapse>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: { AppCollapse, AppCollapseItem },
  data() {
    return {
      viewData: {},
      id: this.$store.state.generalIds.id,
      dataLoad: false,
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    getdata() {
      this.dataLoad = true
      axios.get(`merchant-requests/${this.id}`).then(res => {
        this.viewData = res.data.data
      }).finally(() => {
        this.dataLoad = false
      })
    },
  },
}
</script>

<style scopped>
.download-btn {
  border: 1px solid;
  padding: 0.486rem 1rem;
  border-radius: 0.358rem;
}
</style>
