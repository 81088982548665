<template>
  <div>
    <general-table
      ref="friendsTable"
      :api-url="APIURL"
      :add-type="addType"
      :view-content="viewContent"
      :delete-content="deleteContent"
      :edit-content="editContent"
      :type="type"
      :columns="columns"
      :export-table="exportTable"
      guard="friends"
    />
    <div>
      <b-modal
        ref="view-modal"
        hide-footer
        centered
        title="View Request"
      >
        <ViewRequest />
      </b-modal>
    </div>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import ViewRequest from './ViewRequest.vue'

export default {
  components: { GeneralTable, ViewRequest },
  data() {
    return {
      APIURL: 'merchant-requests',
      addType: '',
      addComponentName: 'add-friend',
      editComponent: 'edit-friend',
      viewContent: true,
      deleteContent: false,
      editContent: false,
      exportTable: false,
      type: 'modal',
      columns: [
        { key: 'id' },
        { key: 'name', label: 'Owner Name' },
        { key: 'email', label: 'Email' },
        { key: 'phone', label: 'Phone' },
        { key: 'business_name', label: 'Business Name' },
        { key: 'actions' },
      ],
    }
  },
}
</script>

<style>

</style>
